export default async function({ req, $axios, store, redirect, params, error: nuxtError, app: { i18n } }) {

    // console.log("check Params #### ", typeof process.env.ENABLE_MAINTENANCE)
    // if(process.env.NODE_ENV === 'production') {
    //     requestURL = `https://localhost:8080/api/mzapps/details/${params.country.toUpperCase()}`
    //     tokenURL = `https://localhost:8080/api/mzapps/token?country_code=${params.country.toUpperCase()}`
    //   }
    if (process.server && process.env.ENABLE_MAINTENANCE && process.env.ENABLE_MAINTENANCE === '1') {
        redirect('/maintanance')
    } else {
        if (req && process.server) {
            
           
            // Get the host from the request headers
            // const host = req.headers.host;

            // Check if the request was made over HTTPS
            const isHttps = req.headers['x-forwarded-proto'] === 'https' || req.protocol === 'https';
            console.log('isHttps',isHttps);

            var data = {
                username: process.env.AUTH_NAME,
                password: process.env.AUTH_KEY,
            }

            // Your logic based on HTTP/HTTPS
            if (isHttps) {
                var AuthtokenURL = `https://${req.headers.host}/api/mzapps/token`;
                var requestURL = `https://${req.headers.host}/api/mzapps/details/${params.country.toUpperCase()}`
                var tokenURL = `https://${req.headers.host}/api/mzapps/token?country_code=${params.country.toUpperCase()}`
            } else {
                var AuthtokenURL = `http://${req.headers.host}/api/mzapps/token`;
                var requestURL = `http://${req.headers.host}/api/mzapps/details/${params.country.toUpperCase()}`
                var tokenURL = `http://${req.headers.host}/api/mzapps/token?country_code=${params.country.toUpperCase()}`
            }

            const Authtoken = await $axios.post(AuthtokenURL, data);
            var auth = params.country.toUpperCase()+' '+Authtoken.data.token
            $axios.setToken(auth, 'auth')
            
            await $axios.$get(requestURL).then(async(res) => {

                var details = res;
                console.log('details Check Params', details);
                if (details !== "") {
                    try {
                        var token = await $axios.$get(tokenURL);
                        console.log("Check Params ", token);
    
                        // $axios.setHeader('Authorization', 'Bearer ' + token.token.accessToken)
                        store.commit('sessionStorage/assignToken', token.token.accessToken)
                        store.commit('sessionStorage/assignMerchant', token.merchant.merchant_id)
                        console.log("Token Response ", token.token);
                        var spilt = details.default_lang;
                        if (spilt !== '') {
                            console.log('spilt:', spilt)
                            i18n.locale = spilt;
                            store.commit('sessionStorage/clearLang', '');
                            store.commit('sessionStorage/assignLang', spilt);
                            return true
                        } else {
                            i18n.locale = 'en-US';
                            store.commit('sessionStorage/assignLang', 'en-US');
                            console.log("Country code### ", spilt, i18n.locale, store.state.sessionStorage.lang);
                            return false
                        } 
                    } catch (error) {
                        console.log("check Error ", error);
                        // redirect('/');
                        nuxtError({
                            statusCode: 400,
                            message: 'redirect',
                        });
                        return Promise.resolve(false); 
                    }
                } else {
                    // redirect('/');
                    nuxtError({
                        statusCode: 400,
                        message: 'redirect',
                    });
                    return Promise.resolve(false);
                }

            }).catch(error => {
                console.log("check Error ", error);
                // redirect('/');
                nuxtError({
                    statusCode: 400,
                    message: 'redirect',
                });
                return Promise.resolve(false);
            })
        }

    }

}